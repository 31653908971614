export default function NotFound() {
  if (typeof window !== 'undefined') {
    window.location.href = '/fr/'
  }

  if (typeof localStorage !== 'undefined') {
    if((localStorage.getItem('language') === '0') || (sessionStorage.getItem('langFooter') === 'english')) {
      if (typeof window !== 'undefined') {
        window.location.href = '/en/'
      }
    } if((localStorage.getItem('language') === '1') || (sessionStorage.getItem('langFooter') === 'french')) {
      if (typeof window !== 'undefined') {
        window.location.href = '/fr/'
      }
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = '/fr/'
      }
    }
  }

  return null
}
